$(document).ready(function () {
    $('.filters-btn').click(function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $('.products-filters.leftSidebar').toggleClass('active');
        $('body').addClass('category-filters-open');
    });
    $('.close-filters').click(function (e) {
        e.preventDefault();
        $('.products-filters.leftSidebar').toggleClass('active');
        $('body').removeClass('category-filters-open');
    });
});
